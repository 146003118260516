import { useEffect } from 'react';

export const useBodyFlex = (): Record<string, never> => {
    useEffect(() => {
        let body = document.querySelector('body');

        if (body) {
            body.classList.add('body-flex');
        }

        return () => {
            body = document.querySelector('body');

            if (body) {
                body.classList.remove('body-flex');
            }
        };
    });

    return {};
};
