import styled from 'styled-components';

import { COLORS } from '@constants/colors';
import { SCREENS } from '@constants/screens';

export const Container = styled.div`
    display: flex;
    flex: 1;
    align-self: stretch;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${COLORS.greyLighter};
`;

export const Animation = styled.div`
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;

    svg {
        max-height: 80vh;
    }
`;

export const Loading = styled.div`
    color: ${COLORS.opacify(COLORS.blueDarker, 0.6)};
    flex: 0.2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 48px;

    ${SCREENS.tabletAndLower} {
        font-size: 26px;
    }

    ${SCREENS.mobileAndLower} {
        font-size: 22px;
    }
`;
