import React from 'react';
import Lottie from 'lottie-react';

import LoadingAnimation from '@assets/animations/sante-academie_animation_loading.json';
import { t } from '@locales/translation';
import { useBodyFlex } from '@hooks/useBodyFlex';

import * as S from './styled';

interface ILoadingScreen {
    title?: string;
}

const LoadingScreen = ({ title }: ILoadingScreen): JSX.Element => {
    useBodyFlex();

    return (
        <S.Container>
            <S.Animation>
                <Lottie animationData={LoadingAnimation} />
            </S.Animation>
            <S.Loading>{title ?? t('loading.loading')}</S.Loading>
        </S.Container>
    );
};

export { LoadingScreen };
